import Vue from 'vue'
import ActiveItem from '@components/ActiveItem'
import Slick from '@components/Slick'
import YoutubeVideo from '@components/YoutubeVideo'
import VueYouTubeEmbed from 'vue-youtube-embed'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.use(VueYouTubeEmbed, { global: true })

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app',
    components: {
      Slick,
      ActiveItem,
      YoutubeVideo
    }
  })
})
