/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// change header display on scroll
// detect if header menu is open

export default class MainHeader {
  constructor () {
    // # GENERAL VARIABLES -------------------------------------
    this.$header = $('[data-is-header]')

    // # SCROLL VARIABLES --------------------------------------
    this.scrollPosition = $(window).scrollTop()

    this.scrollLevelMaxUp = 1
    this.scrollLevelMaxDown = 90 // around header height

    this.scrollClass = 'header--scroll'
    this.minimizedClass = 'header--minimized'

    // # MENU COLLAPSE VARIABLES --------------------------------------
    this.scrollbarWidth = this.checkScrollbar()

    // # FUNCTIONS ------------------------------------------------
    this.bindEvents()
  }

  // CHECK SCROLL LEVEL : know which class header should have
  changeScrollClass (currentScroll, oldScroll, maxScrollUp, maxScrollDown) {
    const self = this

    // on top of the window & scroll up : remove fixed position
    if (($(window).scrollTop() < maxScrollUp) && (currentScroll < oldScroll)) {
      return this.$header.removeClass(self.scrollClass + ' ' + self.minimizedClass)

      // just after header height
    } else if ($(window).scrollTop() > maxScrollDown) {
      // scroll down : hide menu
      if (currentScroll >= oldScroll) {
        return this.$header.addClass(self.minimizedClass)

        // scroll up : show menu
      } else if (currentScroll < oldScroll) {
        return this.$header.addClass(self.scrollClass).removeClass(self.minimizedClass)
      }
    }
  }

  checkScrollbar () {
    const scrollDiv = document.createElement('div')
    scrollDiv.className = 'scrollbar-measure'
    $(scrollDiv).css({
      'overflow-y': 'scroll',
      'width': '100%',
      'height': '100px'
    })
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
  }

  // BIND EVENTS
  bindEvents () {
    const self = this

    // # change header classes on scroll
    // # keep scroll level
    $(window).scroll(function () {
      const scroll = $(window).scrollTop()
      self.changeScrollClass(scroll, self.position, self.scrollLevelMaxUp, self.scrollLevelMaxDown)
      self.position = scroll
    })
  }
}
