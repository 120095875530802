/* eslint no-console:0 */

// Polyfill =====================================================================
import "intersection-observer"

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "bootstrap"
import "select2"
import "lightgallery.js"
import SmoothScroll from 'smooth-scroll'
import "@appPages/vue"

import "stylesheets/application.sass"
import CustomFileInputList from "@utils/custom-file-input-list"
import ScrollTop from "@utils/scroll-top"
import InputSmooth from "@utils/input-smooth"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import MainHeader from "@utils/main-header"
import AnimateScroll from "@utils/animate-scroll"
import Parallax from "@utils/parallax"

// Fonts ===============================================================
import "typeface-roboto"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

window.recaptachCallback = () => {
  document.getElementById("new_contact").submit()
}

document.addEventListener("DOMContentLoaded", () => {
  lightGallery(document.querySelector('#lightgallery'), {
    mode: 'lg-fade',
    cssEasing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    speed: 800,
    download: false
  })
  new InputSmooth()
  new ImageObjectFit()
  new CustomFileInputList()
  new ScrollTop()
  new MainHeader()
  new Parallax()
  new AnimateScroll()
  new SmoothScroll('a[href*="#"]', {
    speed: 800
  })
})
