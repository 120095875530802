<template lang="pug">
  slick(v-on="$listeners" v-bind="$attrs" :options="slickOptions")
    slot
</template>

<script>
import Slick from 'vue-slick'

export default {
  components: { Slick },

  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    defaultOptions () {
      return {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 800,
        autoplay: true,
        cssEase: 'cubic-bezier(0.4, 0, 0.2, 1)',
        autoplaySpeed: 5000
      }
    },

    slickOptions () {
      return {
        ...this.defaultOptions,
        ...this.options
      }
    }
  }
}
</script>
