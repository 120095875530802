<template lang="pug">
  div(class="relative")
    div
      div(class="bg-gradient-l-black-transparent cursor-pointer absolute pin flex flex-col items-center justify-center text-center transition-smooth" @click='setPlaying(true)' v-show="!playing && ready")
        .btn.btn-primary(class="flex items-center transition hover:bg-secondary-dark justify-center  rounded-full bg-secondary h-32 w-32 md:mt-8 relative z-0")
          i.fas.fa-play(class="text-20p ml-1")
        div.h5(class="hidden md:block text-white translate-y-full font-medium") Lancer la video
    youtube(
      :video-id="videoId"
      :player-vars="mergedVars"
      :mute="mute"
      @ready="initPlayer"
      @playing="setPlaying(true)"
      @paused="setPlaying(false)"
      @ended="setPlaying(false)"
      :player-width="playerWidth"
      :player-height="playerHeight"
    )

</template>
<script>
import { getIdFromURL } from 'vue-youtube-embed'

export default {
  props: {
    videoUrl: {
      type: String,
      required: true
    },
    mute: {
      type: Boolean,
      default: false
    },
    playerVars: {
      type: Object,
      default: () => {}
    },
    initialPlaying: {
      type: Boolean,
      default: false
    },
    playerWidth: {
      type: Number,
      default: 735
    },
    playerHeight: {
      type: Number,
      default: 450
    }
  },
  data () {
    return {
      videoId: getIdFromURL(this.videoUrl),
      playing: this.initialPlaying,
      ready: false,
      player: undefined,
      mergedVars: {
        ...{
          autoplay: 0,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
          controls: 1,
          iv_load_policy: 3
        },
        ...this.playerVars
      }
    }
  },
  watch: {
    initialPlaying () {
      this.playing = this.initialPlaying
    },
    playing () {
      if (this.playing) {
        this.player.playVideo()
      } else {
        this.player.pauseVideo()
      }
    }
  },
  methods: {
    initPlayer (event) {
      this.ready = true
      this.player = event.target
      this.player.setPlaybackQuality('hd1080')

      if (this.playing) {
        this.player.playVideo()
      }
    },
    setPlaying (playing) {
      this.playing = playing
    }
  }
}
</script>
